@import '../../../assets/styles/variables';

.title {
  font-size: 40px;
  line-height: 54px;
  font-weight: 900;
  color: #FFFFFF;
  text-align: center;
  -webkit-text-stroke: 2px #464646;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  span {
    display: flex;
      align-items: center;
      justify-content: center;
      max-width: 0;
  }
  //tablet
  @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
    font-size: 20px;
    line-height: 27px;
    -webkit-text-stroke: 1px #464646;
  }
  @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
    font-size: 20px;
    line-height: 27px;
    -webkit-text-stroke: 1px #464646;
  }
}

.container {
  width: 100%;
  overflow: hidden;
  // padding: 0 16px;
}

.autoplay-settings {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 25px 0;
  
  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    margin: 12px 0;
  }

  &__col {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      
    &:nth-child(1),
    &:nth-child(3) {
      width: 30%;
    }
    @media screen and (orientation: portrait) {
      padding: 25px;
      width: 100%;
      &:nth-child(1),
      &:nth-child(3) {
        width: 100%;
      }
    }

    //tablet
    @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
      &:nth-child(2) {
        max-width: 360px;
      }
    }
    @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
      &:nth-child(2) {
        max-width: 380px;
      }
      &:nth-child(3) {
        flex-direction: row;
      }
    }

    // mobile
    @media screen and (max-width: $mobile-landscape-max) and (orientation: landscape) {
      //
    }
    @media screen and (max-width: $mobile-portrait-max) and (orientation: portrait) {
      &:nth-child(3) {
        flex-direction: row;
      }
    }
  }

  .btn {
    width: 128px;
    height: 96px;
    margin: 32px;

    //tablet
    @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
      width: 64px;
      height: 48px;
      margin: 16px;
    }
    @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
      width: 64px;
      height: 48px;
      margin: 16px;
    }
  }

  .toggle {
    // 
  }

  .slider {
    //
  }
}

.autoplay-settings::-webkit-scrollbar {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 20px;
  color: #fff;
  white-space: nowrap;
  -webkit-text-stroke: 1px #464646;

  .value {
    margin-left: 10px;
    font-size: 40px;
    color: var(--game-color);

    .off {
      color: #fff;
    }
    //tablet
    @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
      font-size: 20px;
      margin-left: 5px;
      
    }
    @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
      font-size: 20px;
      margin-left: 5px;
      
    }
  }

  //tablet
  @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
    font-size: 14px;
  }
  @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
    font-size: 14px;
  }

}

.input {
  font-family: var(--font-family);
  padding: 1px 2px;
  letter-spacing: 0px;
}