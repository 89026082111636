@import '../../assets/styles/variables';


.history {
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: 100%;

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }

  @media screen and (max-width: $mobile-portrait-max) and (orientation: portrait) {
    width: 100vw;
  }

  &__title {
    font-size: 40px;
    line-height: 54px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 900;
    -webkit-text-stroke: 2px #464646;
    
    span {
      display: flex;
      align-items: center;
      max-width: 0;
    }   
    @media screen  and (orientation: landscape) {
      align-self: center;
      width: 20%;
    }
    @media screen and (orientation: portrait) {
      margin-bottom: 3vh;
      padding-top: 10vh;
      span {
        max-width: 100%;
        justify-content: center;
      }
    }
    //tablet
    @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
      font-size: 20px;
      line-height: 27px;
      -webkit-text-stroke: 1px #464646;
    }
    @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
      font-size: 20px;
      line-height: 27px;
      -webkit-text-stroke: 1px #464646;
    }
  }

  &__wrap {
    @media screen and (orientation: landscape) {
      width: 60%;
      margin-top: 3vh;
    }
    @media screen and (orientation: portrait) {
      flex-direction: column;
    }
  }

  .header {
    display: flex;
    background-color: #464646;
    &-item {
      color: #fff;
      width: 33.3%;
      padding: 32px;
      font-weight: 900;

      //tablet
      @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
        padding: 14px;
        font-size: 14px;
      }
      @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
        padding: 14px;
        font-size: 14px;
      }

      // mobile
      @media screen and (max-width: $mobile-landscape-max) and (orientation: landscape) {
        padding: 14px;
        font-size: 14px;
      }
      @media screen and (max-width: $mobile-portrait-max) and (orientation: portrait) {
        padding: 14px;
        font-size: 14px;
      }

      &.bet {
        text-align: center;
      }

      &.win {
        text-align: right;
      }
    }
  }

  .container {
    height: 70vh;
    overflow: hidden;
    overflow-y: auto;
    max-height: 70vh;

    @media screen and (orientation: portrait) {
      max-height: 58vh;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #010101;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--game-color);
    }
  }

  &-item {
    display: flex;
    flex-wrap: wrap;
    padding: 32px;
    border-bottom: 2px solid rgba(255,255,255,0.2);

    &__col {
      color: #fff;
      font-size: 16px;
      line-height: 20px;
      
      font-weight: 900;
      width: 33.3%;
      
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 26px;
        line-height: 30px;
      }
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 26px;
        line-height: 30px;
      }
      
      &.date {
        -webkit-text-stroke: 1px #464646;
      }
      &.bet {
        text-align: center;
        color: var(--game-color);
        -webkit-text-stroke: 1px #464646;
      }
      &.win {
        color: var(--game-color);
        text-align: right;
        -webkit-text-stroke: 1px #464646;
      }
      &.gameId {
        width: 100%;
        
        
        .value {
          margin-left: 5px;
          -webkit-user-select: all;
          user-select: all;
          cursor: text;
          
          &::-moz-selection {
            color: #fff;
            background: #000;
          }
          &::selection {
            color: #fff;
            background: #000;
          }
        }
      }
      .btn {
        background: none;

        @media (pointer: fine) {
          &:hover {
            scale: 1.11;
          }
        }
        
        &:active {
          scale: 0.89;
        }
        svg {
          display: block;
          width: 100%;
        }
        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
    @media only screen and (orientation: portrait) {
      flex-wrap: wrap;
      padding: 14px;
     }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 1.25vw 0;

  @media only screen and (orientation: portrait) {
    justify-content: center;
    padding-right: 1.25vw;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    justify-content: center;
  }

  &.invisible {
    display: none;
  }

  &__btn {
    display: inline-flex;
    width: 7.5vw;
    height: 7.5vw;
    margin: 0 0.6vw;
    background: none;
    transition: opacity 0.3s ease;

    @media only screen and (orientation: portrait) {
      width: 7.5vh;
      height: 7.5vh;
    }

    @media (pointer: fine) {
      &:hover:not(:disabled) {
        scale: 1.11;
      }
    }
    
    &:active:not(:disabled) {
      scale: 0.89;
    }
    svg {
      display: block;
      width: 100%;
    }
    &:disabled {
      background: none;
      opacity: 0.5;
      cursor: default;
    }
  }
  .active {
    &:active:not(:disabled) {
      scale: 0.89;
    }
    &:focus:not(:disabled) {
      scale: 0.89;
    }
  }

  
}
.spinner {
  position: relative;
  margin: auto;

  &:after {
    content: ' ';
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    justify-content: center;
    border: 6px solid var(--game-color);
    border-color: var(--game-color) transparent var(--game-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
